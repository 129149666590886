<template>
	<div class="pageview">
		<div class="pageviewtitle">
			<div class="titletab">
				<el-icon @click="backpath">
					<ArrowLeft />
				</el-icon>添加作业
			</div>
			<el-button type="primary" size="default" style="width: 120px;" @click="submitadd" :loading="addloading">保存
			</el-button>
		</div>
		<el-scrollbar>
			<div style="margin-top: 20px;box-sizing: border-box;padding-right: 50px;">
				<div class="row">
					<div class="row-title">作业名称<el-tag type="danger" size="small">必填</el-tag>
					</div>
					<el-input v-model="addjson.homeworkName" placeholder="请输入作业名称"></el-input>
				</div>
				<div class="row">
					<div class="row-title">作业描述
					</div>
					<el-input v-model="addjson.homeworkDesc" placeholder="请输入作业描述"></el-input>
				</div>
				<div class="row">
					<div class="row-title">类型<el-tag type="danger" size="small">必填</el-tag>
					</div>
					<el-select v-model="addjson.type" clearable>
						<el-option label="常规" value="0" />
						<el-option label="周期" value="1" />
					</el-select>
				</div>

				<div class="row">
					<div class="row-title">年级<el-tag type="danger" size="small">必填</el-tag>
					</div>
					<el-select v-model="addjson.gradeId" clearable @change="querychange">
						<el-option v-for="item in gradeList" :label="item.gradeName" :value="item.id" :key="item.id" />
					</el-select>
				</div>

				<div class="row">
					<div class="row-title">学科<el-tag type="danger" size="small">必填</el-tag>
					</div>
					<el-select v-model="addjson.subjectId" clearable @change="querychange">
						<el-option v-for="item in subjectList" :label="item.subjectName" :value="item.id"
							:key="item.id" />
					</el-select>
				</div>

				<div class="row">
					<div class="row-title">单元<el-tag type="danger" size="small">必填</el-tag>
					</div>
					<el-select v-model="addjson.unitId" clearable>
						<el-option v-for="item in unitlist" :label="item.unitName" :value="item.id" :key="item.id" />
					</el-select>
				</div>

				<div class="row">
					<div class="row-title">题目<el-tag type="danger" size="small">必填</el-tag>
					</div>
					<el-button type="primary" size="small" @click="showtable = true">选择题目</el-button>
				</div>
				<div class="row">
					<div class="row-title">已选题目:({{selectList.length}})个 总分:{{getallscro()}}分
					</div>
					<div>
						<div style="padding-top: 10px;" v-for="(item,index) in selectList" :key="item.id">{{index+1}}.
							{{item.questionName}} ({{item.score}}分)
						</div>
					</div>
				</div>
			</div>
		</el-scrollbar>


		<el-dialog v-model="showtable" title="选择题目" width="70%">
			<el-table ref="tableref" :data="questionslist" @selection-change="tablechange" :row-key="getrowkey"
				style="width: 100%" stripe border>
				<el-table-column type="selection" :reserve-selection="true" />
				<el-table-column type="index" align="center" width="50" />
				<el-table-column align="center" prop="questionName" show-overflow-tooltip label="名称" />
				<el-table-column align="center" prop="gradeName" label="年级" />
				<el-table-column align="center" prop="subjectName" label="学科" />
			</el-table>
			<div class="elpagination">
				<el-pagination background layout="prev, pager, next" @current-change="pagechange"
					:page-count="queryjson.pages" :current-page.sync="queryjson.pageNum" />
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ArrowLeft
	} from '@element-plus/icons-vue'
	import {
		subjectslist,
		gradeslist,
		questionslist,
		homeworkadd,
		unitlist
	} from "../../util/api"
	export default {
		components: {
			ArrowLeft
		},
		data() {
			return {
				addloading: false,
				addjson: {
					homeworkName: null,
					homeworkDesc: null,
					isSetlist: 0,
					gradeId: null,
					questionIds: [],
					subjectId: null,
					type: '0', //0常规  1周期
					unitId: null //单元
				},
				subjectList: null,
				gradeList: null,
				showtable: false,
				queryjson: {
					pageNum: 1,
					pageSize: 10,
					pages: 0
				},
				questionslist: null,
				selectList: [],
				unitlist: null,//单元列表
			}
		},
		created() {
			this.getsubjectslist()
			this.getgradeslist()
			this.getquestionslist()
		},
		methods: {
			querychange() { //当学科和年级发生变化  根据学科和年级查询老师信息
				if (this.addjson.gradeId != null) {
					if (this.addjson.subjectId != null) {
						this.getunitlist()
					} else {
						this.unitlist = null
					}
				} else {
					this.unitlist = null
				}
			},
			getunitlist(){
				unitlist({
					pageNum: 1,
					pageSize: 9999999,
					subjectId: this.addjson.subjectId,
					gradeId: this.addjson.gradeId
				}).then((res)=>{
					this.unitlist = res.data.list
				})
			},
			getteacherList() {
				queryteacher({
					subjectId: this.addjson.subjectId,
					gradeId: this.addjson.gradeId
				}).then((res) => {
					console.log(res.data)
					this.teacherList = res.data
				})
			},
			getallscro() {
				let num = 0
				this.selectList.forEach((item) => {
					num += item.score
				})
				return num
			},
			submitadd() {
				console.log(this.addjson)
				if (!this.addjson.homeworkName) {
					this.$message({
						type: 'warning',
						message: '请输入作业名称',
					})
				} else if (!this.addjson.type) {
					this.$message({
						type: 'warning',
						message: '请选择类型',
					})
				} else if (!this.addjson.gradeId) {
					this.$message({
						type: 'warning',
						message: '请选择年级',
					})
				} else if (!this.addjson.subjectId) {
					this.$message({
						type: 'warning',
						message: '请选择学科',
					})
				} else if (!this.addjson.unitId) {
					this.$message({
						type: 'warning',
						message: '请选择单元',
					})
				}else if(this.addjson.questionIds.length <= 0){
					this.$message({
						type: 'warning',
						message: '请选择要添加的题目',
					})
				} else {
					this.addloading = true
					homeworkadd(this.addjson).then(() => {
						this.$message({
							type: 'success',
							message: '添加成功',
						})
						this.addloading = false
						this.backpath()
					})
				}
			},
			tablechange(data) {
				let newlist = []
				data.forEach((item) => {
					newlist.push(item.id)
				})
				this.selectList = data
				this.addjson.questionIds = newlist
			},
			getrowkey(row) {
				return row.id
			},
			getquestionslist() { //获取题目列表
				questionslist({
					pageNum: this.queryjson.pageNum,
					pageSize: this.queryjson.pageSize,
					parentId: 0
				}).then((res) => {
					this.questionslist = res.data.list
					this.queryjson.pages = res.data.pages
				})
			},
			getsubjectslist() {
				subjectslist({
					pageNum: 1,
					pageSize: 99999
				}).then((res) => {
					this.subjectList = res.data.list
				})
			},
			getgradeslist() {
				gradeslist({
					pageNum: 1,
					pageSize: 99999
				}).then((res) => {
					this.gradeList = res.data.list
				})
			},
			pagechange(index) {
				this.queryjson.pageNum = index
				this.getquestionslist()
			},
			backpath() {
				this.$router.back()
			}
		}
	}
</script>

<style scoped>
	.questionname {
		padding-top: 10px;
	}

	.xt {
		padding-top: 10px;
	}

	.el-scrollbar {
		height: calc(100vh - 150px);
	}

	.xximg {
		margin-top: 10px;
	}

	.ppor {
		z-index: 99 !important;
	}
</style>
